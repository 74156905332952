var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.state.metaShow,
          expression: "$store.state.metaShow",
        },
      ],
      staticClass: "login_box",
      attrs: { "element-loading-text": _vm.$store.state.loginText },
      on: {
        click: function ($event) {
          _vm.selectShow = false
        },
      },
    },
    [
      _c("el-row", { staticClass: "login", attrs: { type: "flex" } }, [
        _c("div", { staticClass: "login_right" }, [
          _vm.signType === 1
            ? _c("div", { staticClass: "login_content" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      width: "100%",
                      "justify-content": "flex-end",
                      "margin-bottom": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "100px" } },
                      [_c("chosse-net")],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "sign" }, [
                  _c(
                    "h2",
                    {
                      class: _vm.loginType === "phone" ? "blod" : "",
                      on: {
                        click: function ($event) {
                          _vm.loginType = "phone"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("phoneSign")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "h2",
                    {
                      class: _vm.loginType === "email" ? "blod" : "",
                      on: {
                        click: function ($event) {
                          _vm.loginType = "email"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("emailSign")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm.loginType === "phone"
                  ? _c(
                      "div",
                      { staticClass: "countries" },
                      [
                        _c(
                          "vue-country-intl",
                          {
                            attrs: {
                              schema: "popover",
                              "search-input-placeholder": "",
                            },
                            on: { onChange: _vm.handleCountryChange },
                            model: {
                              value: _vm.phoneCode.dialCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneCode, "dialCode", $$v)
                              },
                              expression: "phoneCode.dialCode",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "phoneCode",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("selectCountry")) +
                                      "\n                : +" +
                                      _vm._s(_vm.phoneCode.dialCode) +
                                      "\n                "
                                  ),
                                  _c("span", {
                                    class: "iti-flag " + _vm.phoneCode.code,
                                  }),
                                ]),
                                _c("i", { staticClass: "iconfont icon-xia" }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.phone.rules === 3,
                      borderY: _vm.Phonefrom.phone.rules === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Phonefrom.phone.value,
                          expression: "Phonefrom.phone.value",
                        },
                      ],
                      class: { red: _vm.Phonefrom.phone.rules === 3 },
                      attrs: {
                        type: "text",
                        placeholder:
                          _vm.loginType === "phone"
                            ? _vm.$t("inputPhone")
                            : _vm.$t("inputEmail"),
                      },
                      domProps: { value: _vm.Phonefrom.phone.value },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.subPhone.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.getfocus("phone")
                        },
                        blur: function ($event) {
                          return _vm.getBlur(_vm.loginType)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.Phonefrom.phone,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.name.rules === 3,
                      borderY: _vm.Phonefrom.name.rules === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Phonefrom.name.value,
                          expression: "Phonefrom.name.value",
                        },
                      ],
                      class: { red: _vm.Phonefrom.name.rules === 3 },
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("inputUserName"),
                      },
                      domProps: { value: _vm.Phonefrom.name.value },
                      on: {
                        keyup: [
                          function ($event) {
                            return _vm.getNameInput()
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.subPhone.apply(null, arguments)
                          },
                        ],
                        focus: function ($event) {
                          return _vm.getfocus("name")
                        },
                        blur: function ($event) {
                          return _vm.getBlur("name")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.Phonefrom.name,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.yzm.rules === 3 && _vm.YZMBtn,
                      borderY: _vm.Phonefrom.yzm.rules === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Phonefrom.yzm.value,
                          expression: "Phonefrom.yzm.value",
                        },
                      ],
                      staticClass: "yzm",
                      class: { red: _vm.Phonefrom.yzm.rules === 3 },
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("inputCode"),
                        maxlength: "6",
                      },
                      domProps: { value: _vm.Phonefrom.yzm.value },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.subPhone.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.Phonefrom.yzm,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        class: _vm.Phonefrom.phone.value ? "yzmShow" : "",
                        attrs: {
                          disabled: !_vm.Phonefrom.phone.value || _vm.YZMBtn,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getyzm()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              !_vm.YZMBtn ? _vm.$t("codeTips") : _vm.yzmText
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.picyzm.rules === 3,
                      borderY: _vm.Phonefrom.picyzm.rules === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Phonefrom.picyzm.value,
                          expression: "Phonefrom.picyzm.value",
                        },
                      ],
                      class: { red: _vm.Phonefrom.picyzm.rules === 3 },
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("inputPicCode"),
                      },
                      domProps: { value: _vm.Phonefrom.picyzm.value },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.subPhone.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.Phonefrom.picyzm,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("img", {
                      staticClass: "picyzm",
                      attrs: { src: _vm.picCode },
                      on: { click: _vm.getPicCode },
                    }),
                  ]
                ),
                _c("div", { staticClass: "input_box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invitationCode,
                        expression: "invitationCode",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("invitationCode"),
                    },
                    domProps: { value: _vm.invitationCode },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.subPhone.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.invitationCode = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.subPhoneLoad,
                        expression: "subPhoneLoad",
                      },
                    ],
                    staticClass: "login_button",
                    class: {
                      yz_button:
                        _vm.YZMBtn &&
                        this.Phonefrom.phone.value &&
                        this.Phonefrom.yzm.value &&
                        this.Phonefrom.name.value,
                    },
                    attrs: { disabled: _vm.subPhoneShow },
                    on: { click: _vm.subPhone },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("next")) + "\n        "
                    ),
                  ]
                ),
                _c("div", { staticClass: "type_button" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.gotoUrl("userLogin", _vm.$route.query)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("accountLogin")) +
                          "\n            "
                      ),
                      _c("s", [_vm._v(_vm._s(_vm.$t("accountLogin2")))]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.signType === 2
            ? _c("div", { staticClass: "login_content" }, [
                _c("h2", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settingPassWordTips")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.password.rules === 3,
                      borderY: _vm.Phonefrom.password.rules === 2,
                    },
                  },
                  [
                    (_vm.passWordType ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password.value,
                              expression: "Phonefrom.password.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingPassWord"),
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.Phonefrom.password.value)
                              ? _vm._i(_vm.Phonefrom.password.value, null) > -1
                              : _vm.Phonefrom.password.value,
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getfocus("password")
                            },
                            blur: function ($event) {
                              return _vm.getBlur("password")
                            },
                            change: function ($event) {
                              var $$a = _vm.Phonefrom.password.value,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password,
                                      "value",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password,
                                      "value",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.Phonefrom.password, "value", $$c)
                              }
                            },
                          },
                        })
                      : (_vm.passWordType ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password.value,
                              expression: "Phonefrom.password.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingPassWord"),
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(_vm.Phonefrom.password.value, null),
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getfocus("password")
                            },
                            blur: function ($event) {
                              return _vm.getBlur("password")
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.Phonefrom.password,
                                "value",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password.value,
                              expression: "Phonefrom.password.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingPassWord"),
                            type: _vm.passWordType ? "text" : "password",
                          },
                          domProps: { value: _vm.Phonefrom.password.value },
                          on: {
                            focus: function ($event) {
                              return _vm.getfocus("password")
                            },
                            blur: function ($event) {
                              return _vm.getBlur("password")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.Phonefrom.password,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    _vm.Phonefrom.password.value
                      ? _c("div", [
                          !_vm.passWordType
                            ? _c("img", {
                                attrs: {
                                  src: require("../assets/images/list_icon_eye_1@2x.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.passWordType = !_vm.passWordType
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.passWordType
                            ? _c("img", {
                                attrs: {
                                  src: require("../assets/images/list_icon_eye_2@2x.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.passWordType = !_vm.passWordType
                                  },
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.password2.rules === 3,
                      borderY: _vm.Phonefrom.password2.rules === 2,
                    },
                  },
                  [
                    (_vm.passWordType2 ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password2.value,
                              expression: "Phonefrom.password2.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password2.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingPassWord2"),
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.Phonefrom.password2.value
                            )
                              ? _vm._i(_vm.Phonefrom.password2.value, null) > -1
                              : _vm.Phonefrom.password2.value,
                          },
                          on: {
                            blur: function ($event) {
                              _vm.getBlur("password2")
                              _vm.checkPasswordLength()
                            },
                            focus: function ($event) {
                              return _vm.getfocus("password2")
                            },
                            change: function ($event) {
                              var $$a = _vm.Phonefrom.password2.value,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password2,
                                      "value",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password2,
                                      "value",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.Phonefrom.password2, "value", $$c)
                              }
                            },
                          },
                        })
                      : (_vm.passWordType2 ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password2.value,
                              expression: "Phonefrom.password2.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password2.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingPassWord2"),
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.Phonefrom.password2.value,
                              null
                            ),
                          },
                          on: {
                            blur: function ($event) {
                              _vm.getBlur("password2")
                              _vm.checkPasswordLength()
                            },
                            focus: function ($event) {
                              return _vm.getfocus("password2")
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.Phonefrom.password2,
                                "value",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password2.value,
                              expression: "Phonefrom.password2.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password2.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingPassWord2"),
                            type: _vm.passWordType2 ? "text" : "password",
                          },
                          domProps: { value: _vm.Phonefrom.password2.value },
                          on: {
                            blur: function ($event) {
                              _vm.getBlur("password2")
                              _vm.checkPasswordLength()
                            },
                            focus: function ($event) {
                              return _vm.getfocus("password2")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.Phonefrom.password2,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    _vm.Phonefrom.password2.value
                      ? _c("div", [
                          !_vm.passWordType2
                            ? _c("img", {
                                attrs: {
                                  src: require("../assets/images/list_icon_eye_1@2x.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.passWordType2 = !_vm.passWordType2
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.passWordType2
                            ? _c("img", {
                                attrs: {
                                  src: require("../assets/images/list_icon_eye_2@2x.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.passWordType2 = !_vm.passWordType2
                                  },
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.passwordShow
                  ? _c("h6", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("passwordError")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm.passwordLengthShow
                  ? _c("h6", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("passwordLenthTip")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _c("h4", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("passwordTips")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.passwordTips.rules === 3,
                      borderY: _vm.Phonefrom.passwordTips.rules === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Phonefrom.passwordTips.value,
                          expression: "Phonefrom.passwordTips.value",
                        },
                      ],
                      class: {
                        red: _vm.Phonefrom.passwordTips.rules === 3,
                      },
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("passwordTips2"),
                      },
                      domProps: { value: _vm.Phonefrom.passwordTips.value },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.subPassword.apply(null, arguments)
                        },
                        focus: function ($event) {
                          return _vm.getfocus("passwordTips")
                        },
                        blur: function ($event) {
                          return _vm.getBlur("passwordTips")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.Phonefrom.passwordTips,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "p",
                  {
                    on: {
                      click: function ($event) {
                        _vm.signType = 1
                        _vm.subPhoneLoad = false
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("previousStep")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.subPasswordLoad,
                        expression: "subPasswordLoad",
                      },
                    ],
                    staticClass: "login_button2",
                    class: {
                      yz_button:
                        this.Phonefrom.password.value &&
                        this.Phonefrom.password2.value,
                    },
                    attrs: { disabled: _vm.subPasswordShow },
                    on: { click: _vm.subPassword },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("sginUp2")) + "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "user_login" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("authorize5")) + "\n      "),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }