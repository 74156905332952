<template>
  <div
    class="login_box"
    @click="selectShow = false"
    v-loading="$store.state.metaShow"
    :element-loading-text="$store.state.loginText"
  >
    <el-row type="flex" class="login">
      <div class="login_right">
        <div class="login_content" v-if="signType === 1">
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: flex-end;
              margin-bottom: 10px;
            "
          >
            <div style="width: 100px">
              <chosse-net />
            </div>
          </div>
          <div class="sign">
            <h2
              @click="loginType = 'phone'"
              :class="loginType === 'phone' ? 'blod' : ''"
            >
              <!-- 手机注册 -->
              {{ $t("phoneSign") }}
            </h2>
            <h2
              @click="loginType = 'email'"
              :class="loginType === 'email' ? 'blod' : ''"
            >
              <!-- 邮箱注册 -->
              {{ $t("emailSign") }}
            </h2>

            <!-- 选择网络 -->
          </div>

          <div class="countries" v-if="loginType === 'phone'">
            <vue-country-intl
              v-model="phoneCode.dialCode"
              schema="popover"
              @onChange="handleCountryChange"
              search-input-placeholder=""
            >
              <div class="phoneCode" slot="reference">
                <div>
                  <!-- 选择国家 -->
                  {{ $t("selectCountry") }}
                  : +{{ phoneCode.dialCode }}
                  <span :class="'iti-flag ' + phoneCode.code"> </span>
                </div>
                <i class="iconfont icon-xia"> </i></div
            ></vue-country-intl>
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.phone.rules === 3,
              borderY: Phonefrom.phone.rules === 2,
            }"
          >
            <input
              type="text"
              :placeholder="
                loginType === 'phone' ? $t('inputPhone') : $t('inputEmail')
              "
              v-model="Phonefrom.phone.value"
              :class="{ red: Phonefrom.phone.rules === 3 }"
              @keyup.enter="subPhone"
              @focus="getfocus('phone')"
              @blur="getBlur(loginType)"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.name.rules === 3,
              borderY: Phonefrom.name.rules === 2,
            }"
          >
            <input
              type="text"
              :placeholder="$t('inputUserName')"
              v-model="Phonefrom.name.value"
              @keyup="getNameInput()"
              @keyup.enter="subPhone"
              :class="{ red: Phonefrom.name.rules === 3 }"
              @focus="getfocus('name')"
              @blur="getBlur('name')"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.yzm.rules === 3 && YZMBtn,
              borderY: Phonefrom.yzm.rules === 2,
            }"
          >
            <input
              type="text"
              class="yzm"
              :placeholder="$t('inputCode')"
              @keyup.enter="subPhone"
              v-model="Phonefrom.yzm.value"
              maxlength="6"
              :class="{ red: Phonefrom.yzm.rules === 3 }"
            />
            <button
              :class="Phonefrom.phone.value ? 'yzmShow' : ''"
              :disabled="!Phonefrom.phone.value || YZMBtn"
              @click="getyzm()"
            >
              {{ !YZMBtn ? $t("codeTips") : yzmText }}
            </button>
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.picyzm.rules === 3,
              borderY: Phonefrom.picyzm.rules === 2,
            }"
          >
            <input
              type="text"
              @keyup.enter="subPhone"
              :class="{ red: Phonefrom.picyzm.rules === 3 }"
              :placeholder="$t('inputPicCode')"
              v-model="Phonefrom.picyzm.value"
            />
            <img :src="picCode" class="picyzm" @click="getPicCode" />
          </div>
          <div class="input_box">
            <input
              type="text"
              @keyup.enter="subPhone"
              :placeholder="$t('invitationCode')"
              v-model="invitationCode"
            />
          </div>
          <button
            class="login_button"
            @click="subPhone"
            :class="{
              yz_button:
                YZMBtn &&
                this.Phonefrom.phone.value &&
                this.Phonefrom.yzm.value &&
                this.Phonefrom.name.value,
            }"
            :disabled="subPhoneShow"
            v-loading="subPhoneLoad"
          >
            <!-- 下一步 -->
            {{ $t("next") }}
          </button>
          <!-- <button @click="getSend()">
            123
          </button> -->
          <div class="type_button">
            <span @click="gotoUrl('userLogin', $route.query)">
              <!-- 账号登录 -->
              {{ $t("accountLogin") }}
              <s>{{ $t("accountLogin2") }}</s>
            </span>
          </div>
        </div>
        <div class="login_content" v-if="signType === 2">
          <h2>
            <!-- 设置密码 -->
            {{ $t("settingPassWordTips") }}
          </h2>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password.rules === 3,
              borderY: Phonefrom.password.rules === 2,
            }"
          >
            <input
              :type="passWordType ? 'text' : 'password'"
              :placeholder="$t('settingPassWord')"
              v-model="Phonefrom.password.value"
              :class="{ red: Phonefrom.password.rules === 3 }"
              @focus="getfocus('password')"
              @blur="getBlur('password')"
            />
            <div v-if="Phonefrom.password.value">
              <img
                v-if="!passWordType"
                src="../assets/images/list_icon_eye_1@2x.png"
                alt=""
                @click="passWordType = !passWordType"
              />
              <img
                v-if="passWordType"
                src="../assets/images/list_icon_eye_2@2x.png"
                alt=""
                @click="passWordType = !passWordType"
              />
            </div>
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password2.rules === 3,
              borderY: Phonefrom.password2.rules === 2,
            }"
          >
            <input
              :type="passWordType2 ? 'text' : 'password'"
              :placeholder="$t('settingPassWord2')"
              v-model="Phonefrom.password2.value"
              :class="{ red: Phonefrom.password2.rules === 3 }"
              @blur="
                getBlur('password2');
                checkPasswordLength();
              "
              @focus="getfocus('password2')"
            />
            <div v-if="Phonefrom.password2.value">
              <img
                v-if="!passWordType2"
                src="../assets/images/list_icon_eye_1@2x.png"
                alt=""
                @click="passWordType2 = !passWordType2"
              />
              <img
                v-if="passWordType2"
                src="../assets/images/list_icon_eye_2@2x.png"
                alt=""
                @click="passWordType2 = !passWordType2"
              />
            </div>
          </div>
          <h6 v-if="passwordShow">
            <!-- 两次输入密码不相符 -->
            {{ $t("passwordError") }}
          </h6>
          <h6 v-if="passwordLengthShow">
            <!-- 密码长度不够提示 -->
            {{ $t("passwordLenthTip") }}
          </h6>
          <h4>
            <!-- 密码提示 -->
            {{ $t("passwordTips") }}
          </h4>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.passwordTips.rules === 3,
              borderY: Phonefrom.passwordTips.rules === 2,
            }"
          >
            <input
              type="text"
              :class="{
                red: Phonefrom.passwordTips.rules === 3,
              }"
              :placeholder="$t('passwordTips2')"
              v-model="Phonefrom.passwordTips.value"
              @keyup.enter="subPassword"
              @focus="getfocus('passwordTips')"
              @blur="getBlur('passwordTips')"
            />
          </div>
          <p
            @click="
              signType = 1;
              subPhoneLoad = false;
            "
          >
            <!-- 上一步 -->
            {{ $t("previousStep") }}
          </p>
          <button
            class="login_button2"
            @click="subPassword"
            :class="{
              yz_button:
                this.Phonefrom.password.value && this.Phonefrom.password2.value,
            }"
            :disabled="subPasswordShow"
            v-loading="subPasswordLoad"
          >
            <!-- 注册 -->
            {{ $t("sginUp2") }}
          </button>
        </div>
        <span class="user_login">
          {{ $t("authorize5") }}
        </span>
      </div>
    </el-row>
  </div>
</template>

<script>
import countries from "@/common/js/countryList";
import {
  registerVerification,
  registerCheck,
  getImgCode,
  getReferrerInfo,
  getInviteCode,
} from "@/api/api";
import { isVerifiedPhone, isVerifiedEmail } from "@/common/js/verification";
import { useTestNet } from "../common/js/baseApi";
import chosseNet from "../components/chosseNet.vue";
export default {
  components: {
    "chosse-net": chosseNet,
  },

  data() {
    return {
      passwordLengthShow: false,
      metanetSdk: null,
      countries: countries,
      invitationCode: localStorage.getItem("referCode") || "",
      phoneCode: {
        code: "cn",
        name: "China (中国)",
        dialCode: 86,
        phoneFormat: "131 2345 6789",
      },
      loginType: "email",
      signType: 1,
      codeimg: "cn",
      selectShow: false,
      subPhoneShow: false,
      subPhoneLoad: false,
      subPasswordShow: false,
      subPasswordLoad: false,
      passwordShow: false,
      yzmText: "Get auth code",
      yzmType: false,
      yzmLoading: false,
      passWordType: false,
      passWordType2: false,
      did: "",
      pk2: "",
      YZMBtn: false,
      userData: "",
      timeNub: 60,
      timeC: "",
      picCode: "",
      cert: "",
      Phonefrom: {
        phone: {
          rules: 1,
          value: "",
        },
        email: {
          rules: 1,
          value: "",
        },
        yzm: {
          rules: 1,
          value: "",
        },
        picyzm: {
          rules: 1,
          value: "",
        },
        name: {
          rules: 1,
          value: "",
        },
        password: {
          rules: 1,
          value: "",
        },
        password2: {
          rules: 1,
          value: "",
        },
        passwordTips: {
          rules: 1,
          value: "",
        },
      },
    };
  },
  watch: {
    loginType() {
      this.$ShowMoney.noneMnemonic.setLoginType(this.loginType);
      this.Phonefrom = {
        phone: {
          rules: 1,
          value: "",
        },
        email: {
          rules: 1,
          value: "",
        },
        yzm: {
          rules: 1,
          value: "",
        },
        name: {
          rules: 1,
          value: "",
        },
        picyzm: {
          rules: 1,
          value: "",
        },
        password: {
          rules: 1,
          value: "",
        },
        password2: {
          rules: 1,
          value: "",
        },
        passwordTips: {
          rules: 1,
          value: "",
        },
      };
    },
    // '$store.state.metaShow': function () {
    //   // console.log(this.$store)
    //   if (!this.$store.state.metaShow) {
    //     let userMeta = this.$store.state.userMeta
    //     if (userMeta.showId && userMeta.infoTxId && userMeta.name) {
    //       if (this.$route.query) {
    //         // console.log(this.$store.state)
    //         this.$utils.getJWTtoken().then(res => {
    //           let data = this.$route.query
    //           // console.log(data)
    //           this.$cookie.set('metaToken', res.data.jwtToken, 60 * 60 * 24 * 30)
    //           localStorage.setItem('localuserData', JSON.stringify(this.userData))
    //           this.gotoUrl('authorize', data)
    //         })
    //       } else {
    //         this.gotoUrl('index')
    //       }
    //     } else {
    //       return this.$message.error(this.$t('metaTxt'))
    //     }
    //   }
    // }
  },
  methods: {
    handleCountryChange(item) {
      console.log(item);
      this.phoneCode.code = item.iso2;
      this.phoneCode.dialCode = item.dialCode;
    },
    checkPasswordLength() {
      if (this.Phonefrom.password2.value && this.Phonefrom.password.value) {
        if (this.Phonefrom.password2.value !== this.Phonefrom.password.value) {
          this.passwordShow = true;
        } else {
          this.passwordShow = false;
        }
      }
      if (
        (this.Phonefrom.password.value &&
          this.Phonefrom.password.value.length < 6) ||
        this.Phonefrom.password.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        (this.Phonefrom.password2.value &&
          this.Phonefrom.password2.value.length < 6) ||
        this.Phonefrom.password2.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        this.Phonefrom.password.value &&
        this.Phonefrom.password.value.length >= 6 &&
        this.Phonefrom.password.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      if (
        this.Phonefrom.password2.value &&
        this.Phonefrom.password2.value.length >= 6 &&
        this.Phonefrom.password2.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      return this.passwordLengthShow && !this.passwordShow;
    },
    // getSend () {
    //   let params = {
    //     'userType': 'phone', 'address': '1P3zRzeP8ALzhVPFHxRwgV6HdjHaPdQ3kG', 'type': 2, 'xpub': 'xpub6BhKREBqjEowUGtB9L7vvvrn9GqgCKR1KEur9NKzk9cASq3tw42cSVfrgfK6v6AxVyR9YcvBDG6QMSpeG84Lew4FTkN6vKVrbxa6hTyCYX8', 'pubkey': '02326668ea739fc7461809be9b7adf5c3a5f096ae1a865c6206c7a52f2d9b0bc20', 'phone': '15800000107', 'headers': { 'timestamp': 1590464199851, 'userName': '15800000107' }
    //   }
    //   senduserbsvaddress(params, 'oauth').then(res => {
    //     console.log(res)
    //   })
    // },
    getNameInput() {
      var l = 0;
      var maxLength = 24;
      for (var i = 0; i < this.Phonefrom.name.value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(this.Phonefrom.name.value[i])) {
          l += 2;
        } else {
          l++;
        }
        if (l > maxLength) {
          this.Phonefrom.name.value = this.Phonefrom.name.value.substr(0, i);
          break;
        }
      }
    },
    // 获取电话区号
    GetPhone(item) {
      this.phoneCode = item;
    },
    alert(item) {
      window.alert(item);
    },
    getPicCode() {
      getImgCode({ characteristic: this.cert }, "oauth").then((res) => {
        console.log(res.data);
        this.cert = res.data.characteristic;
        this.picCode = "data:image/jpeg" + ";base64," + res.data.imageCode;
      });
    },
    // 获取焦点，class 的变化
    getBlur(type) {
      if (!this.Phonefrom[type].value) {
        this.Phonefrom[type].rules = 3;
      } else {
        this.Phonefrom[type].rules = 1;
      }
      if (type === "password2") {
        if (this.Phonefrom.password2.value !== this.Phonefrom.password.value) {
          this.passwordShow = true;
        } else {
          this.passwordShow = false;
        }
      }
      if (type === "phone" && !isVerifiedPhone(this.Phonefrom.phone.value)) {
        this.$message.error(this.$t("phoneErr"));
      } else if (
        type === "email" &&
        !isVerifiedEmail(this.Phonefrom.phone.value)
      ) {
        this.$message.error(this.$t("emailErr"));
      }
    },
    getfocus(type) {
      this.Phonefrom[type].rules = 2;
    },
    setPhoneNub() {
      let phone = this.Phonefrom.phone.value;
      if (this.phoneCode.dialCode !== 86 && this.loginType === "phone") {
        phone = this.phoneCode.dialCode + this.Phonefrom.phone.value;
      }
      return phone;
    },
    getyzm() {
      if (!this.Phonefrom.phone.value) {
        return this.$message.error(this.$t("phoneTipes"));
      }
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (this.loginType === "email" && !re.test(this.Phonefrom.phone.value)) {
        return this.$message.error(this.$t("emaiErr"));
      }
      // if (this.loginType === 'email' && re.test(this.Phonefrom.phone.value)) {
      // }
      // if (!this.Phonefrom.name.value) {
      //   return this.$message.error('用户名不能为空')
      // }
      if (this.YZMBtn) {
        return this.$message.error(this.$t("plaeaseAgainTips"));
      }
      let phone =
        (this.phoneCode.dialCode !== 86 && this.loginType === "phone"
          ? "+"
          : "") + this.setPhoneNub();
      this.yzmLoading = true;
      let that = this;
      let params = {
        // type: 2,
        userType: this.loginType,
        isEnglish: this.$i18n.locale !== "zh",
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      this.YZMBtn = true;
      // timeout()
      this.setYZMText();
      registerVerification(params, "oauth")
        .then(() => {
          // console.log(data)
          this.yzmType = true;
          that.yzmLoading = false;
          that.$message({
            message: this.$t("codeSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          // that.YZMBtn = false;
          // this.yzmLoading = false;
          this.clearYZMText();
          return this.$message.error(this.$t("Error") + err.message);
        });
    },
    setYZMText() {
      this.yzmText = this.timeNub + "s";
      if (this.timeNub > 0) {
        this.timeC = setTimeout(() => {
          --this.timeNub;
          this.yzmText = this.timeNub + "s";
          this.setYZMText();
        }, 1000);
      } else {
        this.YZMBtn = false;
        this.timeNub = 60;
        this.yzmText = "Get auth code";
      }
    },
    clearYZMText() {
      if (this.timeC) {
        clearTimeout(this.timeC);
      }
      this.YZMBtn = false;
      this.timeNub = 60;
      this.yzmText = "Get auth code";
      this.yzmLoading = false;
    },
    async subPhone() {
      if (
        !this.Phonefrom.phone.value ||
        !this.Phonefrom.yzm.value ||
        !this.Phonefrom.name.value
      ) {
        this.subPhoneShow = false;
        if (!this.Phonefrom.name.value) {
          this.Phonefrom.name.rules = 3;
        }
        if (!this.Phonefrom.phone.value) {
          this.Phonefrom.phone.rules = 3;
        }
        if (!this.Phonefrom.yzm.value) {
          this.Phonefrom.yzm.rules = 3;
        }
        return;
      }
      if (!this.yzmType) {
        return this.$notify.error({
          message: this.$t("codeTips2"),
          type: "warning",
        });
      }
      if (this.subPhoneShow) {
        return this.$message.error(this.$t("discussWarning"));
      }
      if (!this.Phonefrom.picyzm.value) {
        return (this.Phonefrom.picyzm.rules = 3);
      }
      if ((this.Phonefrom.yzm.value + "").length < 6) {
        return this.$message.error(this.$t("codenotcomplete"));
      }
      const referCode =
        this.invitationCode || process.env.VUE_APP_DEFAULT_REFERCODE;
      console.log("referCode", referCode);

      if (this.$store.state.chain !== "MVC") {
        try {
          const referInfo = await getReferrerInfo({
            inviteCode: referCode,
          });
          console.log("refer info", referInfo);
          if (referInfo.metaId) {
            this.referrerId = referInfo.metaId;
          } else {
            this.subPhoneShow = false;
            this.subPhoneLoad = false;
            this.invitationCode = "";
            return this.$message.error("Get refer info error");
          }
        } catch (error) {
          this.subPhoneShow = false;
          this.subPhoneLoad = false;
          this.invitationCode = "";
          return this.$message.error("Get refer info error");
        }
      }

      this.registerC();
    },
    // 注册验证
    registerC() {
      this.subPhoneShow = true;
      this.subPhoneLoad = true;
      let phone = this.setPhoneNub();
      let params = {
        userType: this.loginType,
        name: this.Phonefrom.name.value,
        code: this.Phonefrom.yzm.value,
        remark: this.Phonefrom.passwordTips.value,
        imageCode: this.Phonefrom.picyzm.value,
        // promotion: this.invitationCode === '' ? this.invitationCode : '000000',
        characteristic: this.cert,
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      // console.log(params)
      registerCheck(params, "oauth")
        .then((res) => {
          // console.log(res)
          this.pk2 = res.pk2;
          this.userData = res;
          // this.userData.register = res.data.result.registerType
          //   ? res.data.result.registerType
          //   : phone;
          this.userData.register = this.loginType;
          let re =
            /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
          re.test(this.Phonefrom.phone.value)
            ? (this.userData.email = this.Phonefrom.phone.value)
            : (this.userData.phone = this.Phonefrom.phone.value);
          this.subPhoneShow = false;
          this.subPhoneLoad = false;
          this.signType = 2;
        })
        .catch((err) => {
          this.subPhoneShow = false;
          this.subPhoneLoad = false;
          this.subPasswordShow = false;
          this.subPasswordLoad = false;
          return this.$message.error(
            err?.message == "Incorect code"
              ? this.$t("yzmError")
              : err.message == "The graphic verification code is incorrect"
              ? this.$t("graphicError")
              : err.message
          );
        });
    },
    subPassword() {
      if (this.checkPasswordLength()) {
        return false;
      }
      // console.log(!this.Phonefrom.passwordTips.value)
      if (this.Phonefrom.password.value.length <= 5) {
        return this.$message.error("Password must be at least 6 digits");
      }
      if (
        !this.Phonefrom.password.value ||
        !this.Phonefrom.password2.value ||
        !this.Phonefrom.passwordTips.value
      ) {
        this.subPasswordShow = false;
        this.subPasswordLoad = false;
        if (!this.Phonefrom.password.value) {
          return (this.Phonefrom.password.rules = 3);
        }
        if (!this.Phonefrom.password2.value) {
          return (this.Phonefrom.password2.rules = 3);
        }
        if (!this.Phonefrom.passwordTips.value) {
          return (this.Phonefrom.passwordTips.rules = 3);
        }
      }
      if (this.Phonefrom.password.value !== this.Phonefrom.password2.value) {
        this.passwordShow = true;
        return this.$message.error(this.$t("passwordError"));
      }
      if (this.subPasswordShow) {
        return this.$message.error(this.$t("discussWarning"));
      }
      this.subPasswordShow = true;
      this.subPasswordLoad = true;
      this.newWallet();
      // this.userData.passWord = this.Phonefrom.password.value
      // console.log(this.userData)
    },
    newWallet() {
      let phone = this.setPhoneNub();
      // let re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      // re.test(phone) ? this.userData.email = phone : this.userData.phone = phone
      this.userData.headers = this.$ShowMoney.noneMnemonic.getHeader();
      setTimeout(() => {
        let key = this.$ShowMoney.noneMnemonic.createMasterHdPrivateKey(
          phone,
          this.Phonefrom.password.value,
          this.pk2,
          this.userData.tag
        );
        // 实例化钱包
        let that = this;
        let accessKey = this.$utils.encrypt(
          this.Phonefrom.password.value,
          this.pk2.slice(0, 32)
        );
        localStorage.setItem("__Secure-SSID", accessKey);
        that.$cookie.set(
          "__Injector-time",
          true,
          60 * 60 * 24,
          null,
          null,
          true,
          "None"
        );
        // if (this.$store.state.loginShow) {
        //   // console.log('自动登录')
        //   localStorage.setItem('accessKey', accessKey)
        // } else {
        //   // console.log('非自动登录')
        //   sessionStorage.setItem('accessKey', accessKey)
        // }
        this.userData.loginType = this.loginType;
        if (this.phoneCode.dialCode !== 86 && this.loginType === "phone") {
          this.userData.phone =
            this.phoneCode.dialCode + this.Phonefrom.phone.value;
        }
        localStorage.setItem("loginType", this.loginType);
        that.$store.state.userData = this.userData;
        const remark = this.Phonefrom.passwordTips.value;
        const de_mnemonic = this.$ShowMoney.noneMnemonic.decodeMnemonic(
          key.mnemonic,
          key.ppk
        );
        console.log("解码后的助记词：", de_mnemonic);
        const encrypt_mnemonic = this.$utils.aesEncryptMem(
          de_mnemonic,
          this.Phonefrom.password.value
        );
        const encrypt_password = this.$utils.encryptPassword(
          this.Phonefrom.password.value
        );
        this.$utils
          .newWallet(key, "oauth", remark, encrypt_mnemonic, encrypt_password)
          .then(async (r) => {
            //TODO:bug
            that.$store.state.metanet = r;
            that.$forceUpdate();
            that.$cookie.set(
              "userData",
              true,
              60 * 60 * 24 * 30,
              null,
              null,
              true,
              "None"
            );
            that.$cookie.set(
              "__Injector-time",
              true,
              60 * 60 * 24,
              null,
              null,
              true,
              "None"
            );
            const inviteCodeRes = await getInviteCode();
            let userData = that.userData;
            if (inviteCodeRes.promotionCode) {
              userData = {
                ...userData,
                inviteCode: inviteCodeRes.promotionCode,
              };
            }
            localStorage.setItem("__Secure-SSID", accessKey);
            localStorage.setItem("localuserData", JSON.stringify(userData));
            if (useTestNet()) {
              this.$store.state.metanet
                .initMetaId({
                  isMetasv: true,
                  onSuccess: (res) => {
                    that.subPasswordLoad = false;
                    that.$store.state.userData.metaId = res.metaId;
                    that.$store.state.userMeta = res;
                    that.$store.state.infoTxId = res.infoTxId;
                    localStorage.setItem(
                      "localuserData",
                      JSON.stringify(that.$store.state.userData)
                    );
                    let address =
                      that.$store.state.wallet.getTokenReceiveAddress();
                    console.log("getJWTtoken address:", address);
                    that.getJWTtoken(address);
                  },
                })
                .catch((error) => {
                  return this.$message.error(
                    error?.data?.message
                      ? this.$t("Error") + error.data.message
                      : "initmetaid error"
                  );
                });
              setTimeout(() => {
                // console.log('60S 了')
                this.$store.state.metaShow = false;
                this.subPasswordShow = false;
                let address = that.$store.state.wallet.getTokenReceiveAddress();
                this.getJWTtoken(address);
                this.subPasswordLoad = false;
                // let userMeta = this.$store.state.userMeta
              }, 60000);
            } else {
              // 不再订阅
              const isRegister = true;
              //   await this.$store.state.metanet.registerMetasvXpub();
              if (isRegister) {
                that.xupbInterval = setInterval(async () => {
                  const xpubstate = true;
                  // 不再订阅
                  // await that.$store.state.metanet.getMetaSvXpubState();
                  if (xpubstate) {
                    clearInterval(that.xupbInterval);
                    this.$store.state.metanet.checkXpub();
                    that.$store.state.metanet
                      .initMetaId({
                        isMetasv: true,
                        onSuccess: (res) => {
                          that.subPasswordLoad = false;
                          that.$store.state.userData.metaId = res.metaId;
                          that.$store.state.userMeta = res;
                          that.$store.state.infoTxId = res.infoTxId;
                          localStorage.setItem(
                            "localuserData",
                            JSON.stringify(that.$store.state.userData)
                          );
                          let address =
                            that.$store.state.wallet.getTokenReceiveAddress();
                          console.log("getJWTtoken address:", address);
                          that.getJWTtoken(address);
                          this.sendReferrerInfo(
                            this.referrerId,
                            this.invitationCode,
                            res.utxos
                          );
                        },
                      })
                      .then((res) => {
                        this.subPhoneShow = false;
                        this.subPhoneLoad = false;
                        this.subPasswordShow = false;
                        // let address = that.$store.state.wallet.getTokenReceiveAddress()
                        // that.gotoUrl('index')
                      })
                      .catch((error) => {
                        that.gotoUrl("login");
                        return this.$message.error(that.$t("metaidError"));
                      });
                  }
                }, 1200);
              } else {
                return this.$message.error("registerMetasvXpub error");
              }
              setTimeout(() => {
                // console.log('60S 了')
                this.$store.state.metaShow = false;
                this.subPasswordShow = false;
                let address = that.$store.state.wallet.getTokenReceiveAddress();
                this.getJWTtoken(address);
                this.subPasswordLoad = false;
                // let userMeta = this.$store.state.userMeta
              }, 240000);
            }
          })
          .catch((err) => {
            this.subPhoneShow = false;
            this.subPhoneLoad = false;
            this.subPasswordShow = false;
            this.subPasswordLoad = false;
            console.log(err);
            return this.$message.error(this.$t("Error") + err);
          });
      }, 50);
    },
    getJWTtoken(address) {
      console.log(address);
      this.$utils
        .getJWTtoken(address)
        .then((res) => {
          let data = this.$route.query;
          console.log(data, res);
          this.$store.state.metaShow = false;
          this.$cookie.set(
            "metaToken",
            res,
            60 * 60 * 24 * 30,
            null,
            null,
            true,
            "None"
          );
          let userAddress = this.$utils.encrypt(address, this.pk2.slice(0, 32));
          this.$cookie.set(
            "userAddress",
            userAddress,
            60 * 60 * 24 * 30,
            null,
            null,
            true,
            "None"
          );
          localStorage.setItem("localuserData", JSON.stringify(this.userData));
          this.gotoUrl("authorize", data);
        })
        .catch((err) => {
          return this.$message.error("" + err.message);
        });
    },
    getReferrerInfo(code) {
      if (!code || code === "") return;
      getReferrerInfo({
        inviteCode: code,
      })
        .then((res) => {
          if (res.metaId) {
            this.referrerId = res.metaId;
          } else {
            this.message.error("Invalid referCode");
            this.invitationCode = "";
          }
        })
        .catch((error) => {
          this.invitationCode = "";
          this.$message.error(error.message);
        });
    },
    sendReferrerInfo(referrerId, referCode, utxos) {
      referrerId = referrerId || process.env.VUE_APP_DEFAULT_REFERRERID;
      referCode = referCode || process.env.VUE_APP_DEFAULT_REFERCODE;
      const referParams = {
        nodeName: "ShowReferrer",
        metaIdTag: process.env.VUE_APP_IDtags,
        brfcId: "463c81d8015a",
        payCurrency: "BSV",
        payTo: [],
        dataType: "applicaition/json",
        path: "/Protocols/ShowReferrer",
        data: JSON.stringify({
          referrerID: referrerId,
          referCode: referCode,
          sourceType: 2,
        }),
        needConfirm: false,
      };
      if (utxos) {
        referParams.utxos = utxos;
      }
      try {
        console.log("推荐信息上链", referParams);
        this.$store.state.metanet.createProtocolNode(referParams);
      } catch (error) {
        console.log("推荐信息上链失败");
      }
    },
  },
  destroyed() {
    clearInterval(this.timeC);
  },
  created() {
    this.getPicCode();
    const invitationCode = this.$route.query.refCode;
    if (invitationCode) {
      this.invitationCode = invitationCode;
      localStorage.setItem("referCode", invitationCode);
      // this.getReferrerInfo(invitationCode);
    }
    // console.log(this.$store.state)
    // let accessKey = escape(JSON.stringify(key))
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/intl.css";
.login_box {
  width: 100vw;
  min-height: calc(100vh - 68px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f7fa;
}
.user_login {
  display: block;
  text-align: center;
  color: #909399;
  font-size: 12px;
  margin-top: 40px;
}
.mt-15 {
  margin-top: 15px;
}
.login {
  max-width: 720px;
  background: url("../assets/images/background.png") no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  height: 600px;
  display: flex;
  min-width: 600px;
  justify-content: center;
}
.login_right {
  padding: 20px;
  position: relative;
}
::v-deep .vue-country-intl .country-intl-input-wrap {
  border: unset;
}
::v-deep .vue-country-intl .country-intl-label {
  padding-left: 0;
  padding: 7px 16px;
  font-size: 14px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  border-radius: 17px;
  padding: 50px;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    background: url("../assets/images/backgroun_item.png") no-repeat;
    width: 54px;
    height: 54px;
    position: absolute;
    right: -27px;
    bottom: 66px;
  }
  .sign {
    display: flex;
    justify-content: space-between;
  }
  h6 {
    margin: 5px 0;
    font-size: 12px;
    color: #f56c6c;
  }
  h4 {
    margin-top: 20px;
    font-size: 16px;
  }
  p {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #303133;
  }
  h2 {
    color: #303133;
    font-size: 18px;
    margin-bottom: 22px;
    cursor: pointer;
    font-weight: normal;
    font-family: PingFang SC;
  }
  .blod {
    font-weight: bold;
  }
  .countries {
    height: 40px;
    font-size: 15px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
  }
  .picyzm {
    width: unset;
    height: unset;
  }
  .yzmShow {
    background: #eab300;
  }
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: 60%;
  }
  button {
    font-size: 15px;
    color: #f9f9f9;
    width: 96px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
.colorR {
  color: #f56c6c;
}
.type_button {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span {
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    color: #606266;
    font-size: 15px;
    s {
      color: #617fff;
    }
  }
}
.login_button {
  width: 270px;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
.login_button2 {
  width: 270px;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}
.passwordTip {
  text-align: center;
  position: absolute;
  top: -20px;
  background: #fc6d5e;
  height: 80px;
  line-height: 40px;
  width: calc(100% - 40px);
  left: 20px;
  border-radius: 17px;
  z-index: 0;
  transition: 0.2s all linear;
  span {
    color: #fff;
  }
}
.yz_button {
  background: #eab300;
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 768px) {
  .login_left {
    height: calc(100vh - 80px);
  }
  .login_right {
    padding-bottom: 60px;
  }
  .login_button {
    margin-top: 45px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 450px) {
  .login_content {
    max-width: 330px;
  }
}
</style>
